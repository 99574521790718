import { useState } from 'react';
// mui
import { Box, Divider, Grid2, Popover, Stack, styled, Typography, useTheme } from '@mui/material';
// assets
import ukFlag from 'assets/image/flags/gb.png';
import partner1 from 'assets/image/footer/partner1.jpg';
// other
import { Icon } from '@iconify/react';

const StyledTypography = styled(Typography)(() => ({
    color: '#fff6',
    fontSize: 12,
    '&:hover': {
        color: 'white'
    }
}));

const Footer = () => {
    const theme = useTheme();
    const [langAanchor, setLangAnchor] = useState<HTMLElement | null>(null);
    const openLangPopover = (event: React.MouseEvent<HTMLElement>) => {
        setLangAnchor(event.currentTarget as HTMLElement);
    };
    const closeLangPopover = () => {
        setLangAnchor(null);
    };

    return (
        <Grid2 size={12} mt={4} px={1} display="flex" flexDirection="column" gap={2}>
            <Grid2 container justifyContent="flex-end">
                <Stack direction="row" alignItems="center">
                    {/* Current Time Show */}
                    <Box
                        component="span"
                        sx={{
                            height: 28,
                            px: 1,
                            ml: 2,
                            fontSize: 12,
                            borderRadius: 1,
                            lineHeight: '28px',
                            position: 'relative',
                            bgcolor: 'translucent.01'
                        }}
                    >
                        05:01:54
                    </Box>
                    {/* Language Selection Button */}
                    <Stack
                        direction="row"
                        alignItems="center"
                        px={1}
                        sx={{
                            position: 'relative'
                        }}
                    >
                        <Stack
                            aria-describedby="lang-popover"
                            onClick={openLangPopover}
                            direction="row"
                            alignItems="center"
                            sx={{
                                borderRadius: 1,
                                height: 28,
                                px: 1,
                                cursor: 'pointer',
                                bgcolor: 'translucent.01'
                            }}
                        >
                            <Box component="img" src={ukFlag} sx={{ width: 22, height: 14 }} />
                            <Typography sx={{ fontSize: 12, pl: 1 }}>ENG</Typography>
                        </Stack>
                    </Stack>
                    {/* Lanugage Popover */}
                    <Popover
                        id="lang-popover"
                        open={Boolean(langAanchor)}
                        anchorEl={langAanchor}
                        onClose={closeLangPopover}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        sx={{
                            '.MuiPopover-paper': {
                                bgcolor: 'background.default',
                                boxShadow: '0 0 10px 3px #0000004d, inset 0 0 0 300px #ffffff0d'
                            }
                        }}
                    >
                        <Stack sx={{ p: 0.5 }} spacing={0.2}>
                            {new Array(2).fill(1).map((_, i) => (
                                <Stack
                                    key={i}
                                    onClick={() => closeLangPopover()}
                                    direction="row"
                                    alignItems="center"
                                    sx={{
                                        px: 1,
                                        height: 30,
                                        borderRadius: 1,
                                        cursor: 'pointer',
                                        bgcolor: 'translucent.01',
                                        '&:hover': {
                                            bgcolor: 'translucent.02'
                                        }
                                    }}
                                >
                                    <Box component="img" src={ukFlag} sx={{ width: 22, height: 14 }} />
                                    <Typography sx={{ ml: 1, fontSize: 12, userSelect: 'none' }}>ENG</Typography>
                                </Stack>
                            ))}
                        </Stack>
                    </Popover>
                </Stack>
            </Grid2>
            <Divider />
            <Grid2 container flexDirection="column" alignItems="center" gap={2}>
                <Grid2 container size={{ xs: 8, md: 2, sm: 3 }} justifyContent="space-between" py={2}>
                    <Stack gap={1}>
                        <Typography
                            color="#ffffffb3"
                            sx={{
                                textTransform: 'uppercase',
                                fontSize: '12px',
                                marginBottom: '18px'
                            }}
                        >
                            About
                        </Typography>
                        <StyledTypography>General Terms And Conditions</StyledTypography>
                        <StyledTypography>Responsible Gaming</StyledTypography>
                        <StyledTypography>Privacy Policy</StyledTypography>
                    </Stack>
                    <Stack gap={1}>
                        <Typography
                            color="#ffffffb3"
                            sx={{
                                textTransform: 'uppercase',
                                fontSize: '12px',
                                marginBottom: '18px'
                            }}
                        >
                            Help
                        </Typography>
                        <StyledTypography>Sport Betting</StyledTypography>
                        <StyledTypography>FAQs</StyledTypography>
                        <StyledTypography>Casino</StyledTypography>
                    </Stack>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 3, sm: 3 }} gap={2} py={2} justifyContent="center">
                    <Stack gap={1} alignItems="center" sx={{ mb: '48px' }}>
                        <Typography fontSize={10} color="#ffffffb3">
                            REGULATIONS & PARTNERS
                        </Typography>
                        <Box component="img" src={partner1} width={53} height={53} />
                    </Stack>
                    <Stack gap={1} alignItems="center">
                        <Typography
                            fontSize={10}
                            color="#ffffffb3"
                            sx={{
                                mb: '17px'
                            }}
                        >
                            PAYMENTS
                        </Typography>
                    </Stack>
                </Grid2>
            </Grid2>
            <Divider />
            <Grid2 container alignItems="center" justifyContent="space-between" py={2} sx={{ paddingBottom: '48px' }}>
                <Icon icon="tabler:rating-18-plus" width="24" height="24" color={theme.palette.primary.main} />
                <Typography
                    sx={{
                        fontSize: '12px',
                        color: '#ffffff80'
                    }}
                >
                    2021 - 2024 Baladbet
                </Typography>
            </Grid2>
        </Grid2>
    );
};
export default Footer;
