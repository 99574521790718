// mui
import { Box, Button, Checkbox, Divider, FormControlLabel, IconButton, Stack, styled, Typography } from '@mui/material';
// icon
import { Icon } from '@iconify/react';
// logo
import Logo from 'components/Logo/logo';
// components
import FormProvider, { RHFTextField } from 'components/hook-form';
// hooks
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// others
import * as Yup from 'yup';
// css
import './login.css';
// store
import { useDispatch } from 'store/store';
import { closeAuthModal, openAuthModal } from 'store/slices/auth';

const StyledRHFTextField = styled(RHFTextField)(() => ({
    borderRadius: '4px',
    '& label': {
        color: '#fff6',
        fontSize: '12px',
        top: '3px'
    },
    '$ div input': {
        background: '#ffffff26'
    },
    '& label.MuiInputLabel-root.Mui-error': {
        color: '#fff6'
    },
    '& .MuiFormHelperText-root.Mui-error': {
        borderRadius: '3px',
        margin: 0,
        marginTop: '3px',
        paddingLeft: '3px',
        color: 'white',
        background: '#ff344933',
        animation: 'form-control-message 0.24s ease'
    }
}));

const Login = () => {
    const dispatch = useDispatch();
    const LoginSchema = Yup.object().shape({
        email: Yup.string().required('This field is required').email('Email must be a valid email address'),
        password: Yup.string().required('This field is required')
    });

    const defaultValues = {
        email: '',
        password: ''
    };
    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues
    });
    const {
        reset,
        handleSubmit,
        formState: { isSubmitting }
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        try {
            console.log(data);
        } catch (error) {
            console.error(error);
            reset();
        }
    });

    const register = () => {
        dispatch(openAuthModal('register'));
    };
    const closeModal = () => {
        dispatch(closeAuthModal(''));
    };
    return (
        <Box
            sx={{
                minWidth: 400,
                width: '100%',
                pb: 1,
                height: '77vh'
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between" px={1} sx={{ height: '46px' }}>
                <Stack direction="row" alignItems="center" sx={{ flex: 'auto', height: '100%' }}>
                    <Logo />
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{
                            minWidth: 0,
                            maxWidth: '112px',
                            py: 0,
                            px: 0.5
                        }}
                        onClick={register}
                    >
                        REGISTER
                    </Button>
                    <IconButton onClick={closeModal}>
                        <Icon icon="ic:sharp-close" width="24" height="24" />
                    </IconButton>
                </Stack>
            </Stack>
            <Divider />
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Stack p={1} px={1.5} direction="column" gap={1}>
                    <Typography fontSize={14} color="#ffffff80" fontWeight={500}>
                        Already have an account?
                    </Typography>

                    <Typography fontSize="18px" color="white" fontWeight={500} textTransform="uppercase">
                        Sign In, We are waitiong for you
                    </Typography>

                    <StyledRHFTextField variant="filled" name="email" label="Email/Username" />

                    <StyledRHFTextField variant="filled" name="password" label="Password" />

                    <FormControlLabel control={<Checkbox defaultChecked />} label="Remember me" />
                    {!isSubmitting && (
                        <Button
                            color="inherit"
                            size="large"
                            type="submit"
                            variant="contained"
                            fullWidth
                            sx={{
                                color: 'white',
                                background: '#109121',
                                maxWidth: '370px',
                                height: '36px',
                                fontSize: '12px',
                                fontWeight: 500,
                                '&:hover': {
                                    background: '#15be2b',
                                    color: 'white'
                                }
                            }}
                        >
                            SIGN IN
                        </Button>
                    )}
                </Stack>
            </FormProvider>
        </Box>
    );
};
export default Login;
