import React, { useState } from 'react';
import { Link as ReactLink, useLocation } from 'react-router-dom';
// mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
// other
import { Icon } from '@iconify/react';
// assets
import ukFlag from 'assets/image/flags/gb.png';
// config
import { STYLE } from 'config';
// components
import HeaderButton from './HeaderButton';
import Logo from 'components/Logo/logo';
import Login from 'components/Auth/Login';
import Register from 'components/Auth/Register';
// routes
import { paths } from 'routes/paths';
// store
import { useDispatch, useSelector } from 'store/store';
import { closeAuthModal, openAuthModal } from 'store/slices/auth';

// Navigation

const navigation = [
    {
        title: 'Live',
        icon: <Icon icon="carbon:circle-filled" style={{ width: 22, height: 22 }} />,
        path: paths.sports.live.event_view,
        isMain: true,
        children: [
            {
                title: 'Event View',
                path: paths.sports.live.event_view
            },
            {
                title: 'Live Calendar',
                path: paths.sports.live.live_calendar
            },
            {
                title: 'Results',
                path: paths.sports.live.results
            }
        ]
    },
    {
        title: 'Sports',
        icon: <Icon icon="material-symbols-light:sports-and-outdoors" style={{ width: 22, height: 22 }} />,
        path: paths.sports.pre.event_view,
        isMain: true,
        children: [
            {
                title: 'Event View',
                path: paths.sports.pre.event_view
            },
            {
                title: 'Live Calendar',
                path: paths.sports.pre.live_calendar
            },
            {
                title: 'Results',
                path: paths.sports.pre.results
            }
        ]
    },
    {
        title: 'Casino',
        icon: <Icon icon="map:casino" style={{ width: 22, height: 22 }} />,
        path: '',
        isMain: true,
        children: [
            {
                title: 'Home',
                path: ''
            },
            {
                title: 'Tournaments',
                path: ''
            },
            {
                title: 'Jackpots',
                path: ''
            }
        ]
    },
    {
        title: 'Live Casino',
        icon: <Icon icon="map:casino" style={{ width: 22, height: 22 }} />,
        path: '',
        isMain: false
    },
    {
        title: 'Games',
        icon: <Icon icon="fad:random-1dice" style={{ width: 22, height: 22 }} />,
        path: '',
        isMain: false
    },
    {
        title: 'Virtual sports',
        icon: <Icon icon="mdi:horse-variant" style={{ width: 22, height: 22 }} />,
        path: '',
        isMain: false
    },
    {
        title: 'E-sports',
        icon: <Icon icon="material-symbols:sports-esports-rounded" style={{ width: 22, height: 22 }} />,
        path: '',
        isMain: false
    }
];
const MoreNavData = {
    title: 'More',
    icon: <Icon icon="ci:more-grid-big" style={{ width: 22, height: 22 }} />,
    path: '',
    children: [
        {
            title: 'Live Casino',
            icon: <Icon icon="map:casino" style={{ width: 22, height: 22 }} />,
            path: '',
            isMain: false
        },
        {
            title: 'Games',
            icon: <Icon icon="fad:random-1dice" style={{ width: 22, height: 22 }} />,
            path: '',
            isMain: false
        },
        {
            title: 'Virtual sports',
            icon: <Icon icon="mdi:horse-variant" style={{ width: 22, height: 22 }} />,
            path: '',
            isMain: false
        },
        {
            title: 'E-sports',
            icon: <Icon icon="material-symbols:sports-esports-rounded" style={{ width: 22, height: 22 }} />,
            path: '',
            isMain: false
        }
    ]
};
const sportNavigation = [
    {
        title: 'Event View',
        path: paths.sports.pre.event_view
    },
    {
        title: 'Live Calendar',
        path: paths.sports.pre.live_calendar
    },
    {
        title: 'Results',
        path: paths.sports.pre.results
    }
];

// Auth Modal

const Header = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { pathname } = useLocation();
    const { authModal } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [langAanchor, setLangAnchor] = useState<HTMLElement | null>(null);
    const [moreAanchor, setMoreAnchor] = useState<HTMLElement | null>(null);

    const openLangPopover = (event: React.MouseEvent<HTMLElement>) => {
        setLangAnchor(event.currentTarget as HTMLElement);
    };

    const openMorePopover = (event: React.MouseEvent<HTMLElement>) => {
        setMoreAnchor(event.currentTarget as HTMLElement);
    };

    const closeLangPopover = () => {
        setLangAnchor(null);
    };

    const closeMorePopover = () => {
        setMoreAnchor(null);
    };

    const handleClose = () => {
        dispatch(closeAuthModal(''));
    };
    const handleOpen = (page: string) => {
        dispatch(openAuthModal(page));
    };

    return (
        <React.Fragment>
            <Box
                sx={{
                    mb: 1,
                    top: 0,
                    width: 1,
                    zIndex: 952,
                    position: 'sticky',
                    bgcolor: 'background.default',
                    borderColor: 'translucent.01',
                    borderWidth: 1,
                    gridArea: 'header',
                    borderStyle: 'none none solid none'
                }}
            >
                <Box component="header" sx={{ px: 1 }}>
                    <Stack direction="row" alignItems="center" sx={{ height: STYLE.headerHeight }}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ flex: 'auto', height: isMobile ? STYLE.m_headerHeight : STYLE.headerHeight }}
                        >
                            <Logo />
                        </Stack>

                        {/* Sign In & Register Buttons */}
                        <Stack direction="row" alignItems="center" gap={0.5}>
                            <Button
                                sx={{
                                    py: 0,
                                    px: 0.5,
                                    height: 28,
                                    minWidth: 0,
                                    maxWidth: '112px',
                                    position: 'relative',
                                    textDecoration: 'underline',
                                    '&::after': {
                                        left: '-5px',
                                        content: `''`,
                                        position: 'absolute',
                                        width: '1px',
                                        height: '30px',
                                        bottom: 0,
                                        top: 0,
                                        margin: 'auto',
                                        zIndex: -1,
                                        bgcolor: 'translucent.01'
                                    }
                                }}
                                onClick={() => handleOpen('login')}
                            >
                                SING IN
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    height: 28,
                                    minWidth: 0,
                                    maxWidth: '112px',
                                    py: 0,
                                    px: 0.5,
                                    '&::after': {
                                        right: '-10px',
                                        content: `''`,
                                        position: 'absolute',
                                        width: '1px',
                                        height: '30px',
                                        bottom: 0,
                                        top: 0,
                                        margin: 'auto',
                                        zIndex: -1,
                                        bgcolor: 'translucent.01'
                                    }
                                }}
                                onClick={() => handleOpen('register')}
                            >
                                REGISTER
                            </Button>
                        </Stack>

                        {/* Language Selection Button */}
                        <Stack
                            direction="row"
                            alignItems="center"
                            px={1}
                            sx={{
                                position: 'relative',
                                '&::after': {
                                    left: 0,
                                    content: `''`,
                                    position: 'absolute',
                                    width: '1px',
                                    height: '30px',
                                    bottom: 0,
                                    top: 0,
                                    margin: 'auto',
                                    zIndex: -1,
                                    bgcolor: 'translucent.01'
                                }
                            }}
                        >
                            <Stack
                                aria-describedby="lang-popover"
                                onClick={openLangPopover}
                                direction="row"
                                alignItems="center"
                                sx={{
                                    pl: 1,
                                    minHeight: 36,
                                    cursor: 'pointer'
                                }}
                            >
                                <Box component="img" src={ukFlag} sx={{ width: 22, height: 14 }} />
                                <Stack
                                    alignItems="center"
                                    sx={{
                                        flexDirection: 'row',
                                        display: isMobile ? 'none' : 'flex'
                                    }}
                                >
                                    <Typography sx={{ fontSize: 12, pl: 1 }}>ENG</Typography>
                                    <Icon icon="lsicon:down-filled" style={{ paddingLeft: 1 }} />
                                </Stack>
                            </Stack>
                        </Stack>

                        {/* Current Time Show */}
                        <Box
                            component="span"
                            sx={{
                                height: 28,
                                px: 0.5,
                                ml: 2,
                                fontSize: 10,
                                borderRadius: 1,
                                lineHeight: '28px',
                                position: 'relative',
                                bgcolor: 'translucent.01',
                                '&::after': {
                                    left: -15,
                                    content: `''`,
                                    position: 'absolute',
                                    width: '1px',
                                    height: '30px',
                                    bottom: 0,
                                    top: 0,
                                    margin: 'auto',
                                    zIndex: -1,
                                    bgcolor: 'translucent.01'
                                }
                            }}
                        >
                            05:01:54
                        </Box>

                        {/* More */}
                        <Box
                            sx={{
                                ml: 2,
                                position: 'relative',
                                '&::after': {
                                    left: -10,
                                    content: `''`,
                                    position: 'absolute',
                                    width: '1px',
                                    height: '30px',
                                    bottom: 0,
                                    top: 0,
                                    margin: 'auto',
                                    zIndex: -1,
                                    bgcolor: 'translucent.01'
                                }
                            }}
                        >
                            <IconButton
                                aria-describedby="more-popover"
                                onClick={openMorePopover}
                                sx={{
                                    p: 0,
                                    width: 34,
                                    height: 28,
                                    borderRadius: 1,
                                    bgcolor: 'translucent.01',
                                    '&:hover': { bgcolor: 'translucent.02' }
                                }}
                            >
                                <Icon icon="bx:dots-vertical" />
                                <Typography
                                    sx={{
                                        width: 18,
                                        height: 18,
                                        fontSize: 11,
                                        color: 'common.white',
                                        position: 'absolute',
                                        top: -2,
                                        right: -8,
                                        borderRadius: 50,
                                        bgcolor: 'primary.main'
                                    }}
                                >
                                    3
                                </Typography>
                            </IconButton>
                        </Box>
                    </Stack>

                    <Stack
                        component="nav"
                        direction="row"
                        sx={{
                            bgcolor: 'translucent.04',
                            borderRadius: 1,
                            height: STYLE.subHeaderHeight,
                            position: 'relative'
                        }}
                    >
                        {isMobile
                            ? navigation
                                  .filter((item) => item.isMain === true)
                                  .map((item, rIndex) => <HeaderButton key={rIndex} index={rIndex} navData={item} />)
                            : navigation.map((item, rIndex) => (
                                  <HeaderButton key={rIndex} index={rIndex} navData={item} />
                              ))}
                        {isMobile && (
                            <HeaderButton sx={{ position: 'absolute', right: 0 }} index={6} navData={MoreNavData} />
                        )}
                    </Stack>
                    {pathname.indexOf('home') < 0 && (
                        <Stack
                            component="nav"
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={3}
                            sx={{ height: STYLE.subHeaderHeight }}
                        >
                            {sportNavigation.map((sport, rIndex) => {
                                const isActiveTab = pathname.split('/')[3] === sport.path.split('/')[3];
                                return (
                                    <Stack
                                        key={rIndex}
                                        alignItems="center"
                                        justifyContent="center"
                                        component={ReactLink}
                                        to={sport.path}
                                        sx={{
                                            p: 0,
                                            height: 1,
                                            borderRadius: 0,
                                            letterSpacing: 0.5,
                                            color: isActiveTab ? 'common.white' : 'translucent.03',
                                            boxShadow: isActiveTab
                                                ? (theme) => `inset 0 -2px 0 0 ${theme.palette.primary.main}`
                                                : 'none',
                                            transition: 'color .24s, box-shadow .24s',
                                            '&:hover': {
                                                color: 'common.white',
                                                boxShadow: (theme) => `inset 0 -2px 0 0 ${theme.palette.primary.main}`
                                            }
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 16,
                                                fontWeight: 500
                                            }}
                                        >
                                            {sport.title}
                                        </Typography>
                                    </Stack>
                                );
                            })}
                        </Stack>
                    )}
                </Box>

                {/* Lanugage Popover */}
                <Popover
                    id="lang-popover"
                    open={Boolean(langAanchor)}
                    anchorEl={langAanchor}
                    onClose={closeLangPopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    sx={{
                        '.MuiPopover-paper': {
                            p: 0,
                            bgcolor: 'background.default',
                            boxShadow: '0 0 10px 3px #0000004d, inset 0 0 0 300px #ffffff0d'
                        }
                    }}
                >
                    <Stack sx={{ p: 0.5 }} spacing={0.2}>
                        {new Array(2).fill(1).map((_, i) => (
                            <Stack
                                key={i}
                                onClick={() => closeLangPopover()}
                                direction="row"
                                alignItems="center"
                                sx={{
                                    px: 1,
                                    height: 30,
                                    borderRadius: 1,
                                    cursor: 'pointer',
                                    bgcolor: 'translucent.01',
                                    '&:hover': {
                                        bgcolor: 'translucent.02'
                                    }
                                }}
                            >
                                <Box component="img" src={ukFlag} sx={{ width: 22, height: 14 }} />
                                <Typography sx={{ ml: 1, fontSize: 12, userSelect: 'none' }}>ENG</Typography>
                            </Stack>
                        ))}
                    </Stack>
                </Popover>

                {/* More Popover */}
                <Popover
                    id="more-popover"
                    open={Boolean(moreAanchor)}
                    anchorEl={moreAanchor}
                    onClose={closeMorePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    sx={{
                        '.MuiPopover-paper': {
                            ml: -0.75,
                            mt: 1,
                            bgcolor: 'background.default',
                            boxShadow: '0 0 10px 3px #0000004d, inset 0 0 0 300px #ffffff33'
                        }
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <Stack spacing={0.2}>
                        {new Array(2).fill(1).map((_, i) => (
                            <IconButton key={i}>
                                <Icon icon="zondicons:notification" style={{ width: 18, height: 18 }} />
                            </IconButton>
                        ))}
                    </Stack>
                </Popover>
                {/* Auth Modal */}

                <Dialog
                    open={authModal.status}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{
                        '& .MuiPaper-root': {
                            borderRadius: '4px',
                            overflowY: 'unset',
                            background: '#000c24'
                        }
                    }}
                >
                    <Box>{authModal.page === 'login' ? <Login /> : <Register />}</Box>
                </Dialog>
            </Box>
        </React.Fragment>
    );
};

export default Header;
