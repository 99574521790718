import { lazy } from 'react';
// layouts
import SportLayout from 'layouts/SportLayout';
// ----------------------------------------------------------------------
// OVERVIEW
const EventView = lazy(() => import('pages/sports/Event'));
const LiveCalendar = lazy(() => import('pages/sports/LiveCalendar'));
const Result = lazy(() => import('pages/sports/Result'));
// ----------------------------------------------------------------------

export const sportsRoutes = [
    {
        path: '/sports/live',
        element: <SportLayout />,
        children: [
            {
                path: 'event-view',
                element: <EventView />
            },
            {
                path: 'live-calendar',
                element: <LiveCalendar />
            },
            {
                path: 'results',
                element: <Result />
            }
        ]
    },
    {
        path: '/sports/pre-match',
        element: <SportLayout />,
        children: [
            {
                path: 'event-view',
                element: <EventView />
            },
            {
                path: 'live-calendar',
                element: <LiveCalendar />
            },
            {
                path: 'results',
                element: <Result />
            }
        ]
    }
];
