import { Suspense, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
// components
import Header from 'components/Header';
import SportsFooter from 'components/Footer/SportsFooter';
import { LoadingScreen } from 'components/loading-screen';

const SportLayout = () => {
    const location = useLocation();

    const isResultPage = useMemo(() => {
        const param = location.pathname.split('/').pop();
        return param === 'results';
    }, [location.pathname]);

    return (
        <Suspense fallback={<LoadingScreen />}>
            <Box
                sx={{
                    width: 1,
                    display: 'grid',
                    minHeight: '100vh',
                    userSelect: 'none',
                    gridTemplateColumns: isResultPage ? '100%' : '1fr 330px',
                    gridTemplateRows: 'auto 1fr auto',
                    gridTemplateAreas: isResultPage
                        ? `"header" "content" "footer" "betslip"`
                        : `"header header" "content betslip" "footer footer"`
                }}
            >
                <Header />
                <Outlet />
                <SportsFooter />
            </Box>
        </Suspense>
    );
};

export default SportLayout;
