import { Outlet } from 'react-router-dom';
// @mui
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
// config
import { STYLE } from 'config';

const Wrapper = () => (
    <Box component="main">
        <Container sx={{ maxWidth: `${STYLE.layoutWidth}px !important`, px: 0 }}>
            <Outlet />
        </Container>
    </Box>
);

export default Wrapper;
