import { Navigate, useRoutes } from 'react-router-dom';
//
import { mainRoutes } from './main';
import { sportsRoutes } from './sports';

// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        // Main routes
        ...mainRoutes,
        ...sportsRoutes,
        // No match 404
        { path: '*', element: <Navigate to="/home" replace /> }
    ]);
}
